<template>
  <div>
    <template v-if="config.splitType === 1">
      <v-row v-for="(item, index) in config.Panel1" :key="index">
        <v-col> <component :is="item" /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel2" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <v-row v-for="(item, index) in config.Panel3" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="config.splitType === 2">
      <v-row>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel1" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <v-row v-for="(item, index) in config.Panel2" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="config.splitType === 3">
      <v-row>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel1" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row v-for="(item, index) in config.Panel2" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel3" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Schedule from '../pages/Schedule.vue';
import Library from '../pages/Library.vue';
import Information from '../pages/Information.vue';
// import Workflow from '../pages/Workflow.vue';
import { mapState, mapActions } from 'vuex';
import Workflow from '../pages/Workflow2.vue';
import TimeCard from '../pages/TimeCard.vue';

export default {
  components: {
    Schedule,
    Library,
    Workflow,
    Information,
    TimeCard
  },
  data: () => ({
    config: {
      splitType: 1,
      Panel1: ['Information'],
      Panel2: ['TimeCard', 'Library', 'Workflow'],
      Panel3: ['Schedule']
    }
  }),
  mounted() {
    // this.config = {
    //   splitType: 2,
    //   Panel1: ['TimeCard', 'Information', 'Library', 'Workflow'],
    //   Panel2: ['Schedule'],
    //   Panel3: []
    // };
    // this.config = {
    //   splitType: 3,
    //   Panel1: ['TimeCard', 'Workflow'],
    //   Panel2: ['Schedule'],
    //   Panel3: ['Information', 'Library']
    // };
  },
  created: function () {},
  computed: {
    ...mapState({})
  },
  methods: {
    ...mapActions(['fetchUserList']),
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  }
};
</script>

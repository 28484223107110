import moment from 'moment';

moment.locale('ja', {
  weekdays: [
    '日曜日',
    '月曜日',
    '火曜日',
    '水曜日',
    '木曜日',
    '金曜日',
    '土曜日'
  ],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
});

const dateformat = {
  autoFormat(date) {
    // const baseFormat = 'YYYY年MM月DD日 HH:mm';
    const lastYearFormat = 'YYYY年MM月DD日';
    const toYearFromat = 'MM月DD日';
    const toDayFromat = 'HH:mm';

    let todayDate = new Date();
    let dateDate = new Date(date);
    if (todayDate.getFullYear() == dateDate.getFullYear()) {
      //今年
      if (
        todayDate.getMonth() == dateDate.getMonth() &&
        todayDate.getDate() == dateDate.getDate()
      ) {
        //当日
        return this.format(date, toDayFromat);
      } else {
        //今年
        return this.format(date, toYearFromat);
      }
    } else {
      //去年
      return this.format(date, lastYearFormat);
    }
  },
  fullFormat(date) {
    const baseFormat = 'YYYY年MM月DD日 HH:mm';
    return this.format(date, baseFormat);
  },
  format(date, formatString) {
    return moment(new Date(date)).format(formatString);
  }
};

export default dateformat;

<template>
  <v-sheet class="frame" v-resize="onResize">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" tile text class="pa-0">
        <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon"
          >mdi-clipboard-flow</v-icon
        >
        <div class="text-decoration-underline text-body-1">ワークフロー</div>
      </v-btn>
      <template v-if="isMainPage()">
        <template v-if="ismobile">
          <v-btn class="mx-2" @click="dispConfigDialogOpen"> 条件 </v-btn>
        </template>
        <template v-else>
          <v-btn class="mx-5" @click="dispConfigDialogOpen"> 条件変更 </v-btn>
          {{ getDispConfigText() }}
        </template>
      </template>
      <v-spacer></v-spacer>

      <v-btn color="info" @click="startEdit">追加</v-btn>
    </v-toolbar>
    <v-select
      prepend-icon="mdi-tree"
      label="申請区分"
      :items="this.getDispmodeList()"
      item-text="disp_name"
      item-value="id"
      v-model="dispmode"
      style="max-width: 300px"
      dense
      v-show="disp_cols === 12"
      @change="setDispMode"
    >
    </v-select>
    <v-row ref="card">
      <v-col cols="12" :md="disp_cols">
        <v-list dense v-show="disp_cols !== 12">
          <v-list-item-group v-model="dispmode" mandatory color="indigo">
            <v-list-item
              v-for="(item, index) in this.getDispmodeList()"
              :key="index"
              link
              @click="setDispMode(item.id)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <v-col cols="12" :md="data_cols">
        <v-data-table
          ref="datatable"
          :headers="getHeaders()"
          :items="listitem"
          @click:row="showEvent"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100, 250, 500, -1],
            'items-per-page-text': '行/ページ:'
          }"
          :items-per-page.sync="config[getItemsPerPage()]"
          must-sort
          sort-by="inserttime"
          :sort-desc="true"
          mobile-breakpoint="0"
        >
          <template v-slot:[`item.filename`]="{ item }"
            ><div>
              {{ item.filename }}
            </div>
          </template>
          <template v-slot:[`item.directry_id`]="{ item }">
            {{ getDirectryName(item.directry_id) }}
          </template>
          <template v-slot:[`item.statusId`]="{ item }">
            <v-chip :color="getStatusColor(item)" dark>
              {{ statusTextMap[item.statusId] }}
            </v-chip>
          </template>
          <template v-slot:[`item.updatetime`]="{ item }">
            {{ getDateFormat(item.updatetime) }}
          </template>
          <template v-slot:[`item.inserttime`]="{ item }">
            {{ getDateFormat(item.inserttime) }}
          </template>
          <template v-slot:[`item.insertuser`]="{ item }">
            {{ getUserName(item.insertuser) }}
          </template>
          <template v-slot:[`item.dispUser`]="{ item }">
            {{ item.dispUser }}
          </template>
          <template v-slot:[`item.filesize`]="{ item }">
            {{ fileSizeFormat(item.filesize) }}
          </template>
        </v-data-table>

        <custom-dialog v-model="selectedOpen">
          <template v-slot:title>ワークフロー</template>
          <template v-slot:body>
            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="2">
                  <div class="pa-0 ma-0 text-subtitle-1">決裁状況</div>
                </v-col>
                <v-col cols="3">
                  <div class="pa-0 ma-0 text-subtitle-1" style="color: red">
                    {{ getStatusText(selectedEvent) }}
                  </div>
                </v-col>
                <v-col cols="2">
                  <div class="pa-0 ma-0 text-subtitle-1">申請日</div>
                </v-col>
                <v-col cols="5">
                  <div class="pa-0 ma-0 text-subtitle-1">
                    {{ fullDateFormat(selectedEvent.inserttime) }}
                  </div>
                </v-col>
              </v-row>

              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-select
                    item-text="disp_name"
                    item-value="id"
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-bookmark'
                        : ''
                    "
                    :items="workflowTemplate"
                    v-model="selectedEvent.template_id"
                    @change="category_change"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    hide-details="auto"
                    readonly
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                        カテゴリ
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <dynamic-work-flow
                :rows="selectedRows"
                :data="selectedData"
                :readonly="true"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :ismobile="ismobile"
                :useIcon="getDispStyle(config.dispStyle).useIcon"
                :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
              />
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="12">
                  <v-chip
                    v-for="(item, index) in selectedEvent.files"
                    :key="index"
                    @click="downloadCountUp(item)"
                    >{{ getFileName(item) }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col cols="12">
                  <v-card class="mx-1 mt-3" color="background_sub">
                    <v-container
                      class="px-6 text-body-2"
                      v-if="selectedEvent.route"
                    >
                      <v-row class="pa-0">
                        <v-col class="pa-0 text-subtitle-1">決裁履歴</v-col>
                      </v-row>
                      <v-row class="pa-0">
                        <v-col class="pa-0" cols="1">申請</v-col>
                        <v-col class="pa-0" cols="3">{{
                          getUserName(selectedEvent.insertuser)
                        }}</v-col>
                        <v-col class="pa-0" cols="4"></v-col>
                        <v-col class="pa-0 text-right" cols="4">{{
                          fullDateFormat(selectedEvent.inserttime)
                        }}</v-col>
                      </v-row>

                      <template v-for="(item, index) in selectedEvent.route">
                        <!-- 謎のエラーが出るが、対応するためにはvue3が必要 -->
                        <v-row :key="`${index}A`"><v-divider /></v-row>
                        <v-row :key="`${index}B`" class="pa-0">
                          <v-col class="pa-0" cols="1">{{
                            getAcceptTitle(selectedEvent, item)
                          }}</v-col>
                          <template
                            v-if="
                              !isNextAuthUser(selectedEvent, item) ||
                              item !== getUserId()
                            "
                          >
                            <v-col class="pa-0" cols="3">{{
                              getUserName(item)
                            }}</v-col>
                            <v-col class="pa-0" cols="4">{{
                              getAcceptMessage(selectedEvent, item)
                            }}</v-col>
                            <v-col class="pa-0 text-right" cols="4">{{
                              getAcceptTime(selectedEvent, item)
                            }}</v-col>
                          </template>
                          <template v-else>
                            <v-col class="pa-0" cols="11">
                              {{ getUserName(item) }}
                              <v-row class="px-3">
                                <v-textarea
                                  rows="3"
                                  v-model="acceptData.message"
                                />
                              </v-row>
                              <v-row class="px-3" justify="end" align="center">
                                <v-btn color="primary" @click="doAccept"
                                  >承認する</v-btn
                                >
                              </v-row>
                              <v-row class="px-3" justify="end" align="center">
                                <v-select
                                  style="max-width: 300px"
                                  prepend-icon="mdi-account-circle"
                                  label="差し戻し先"
                                  item-text="disp_name"
                                  item-value="id"
                                  :items="acceptData.route_users"
                                  v-model="acceptData.user_id"
                                />
                                に<v-btn color="warning" @click="doReject"
                                  >差し戻す</v-btn
                                >
                              </v-row>
                            </v-col>
                          </template>
                        </v-row>
                      </template>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn
              color="info"
              v-show="canReapply(selectedEvent)"
              @click="startEditing"
            >
              再申請
            </v-btn>
            <v-btn
              color="warning"
              v-show="canDeleteEvent(selectedEvent)"
              @click="deleteData"
            >
              削除
            </v-btn>
            <v-btn color="accent" @click="selectedOpen = false">
              キャンセル
            </v-btn>
          </template>
        </custom-dialog>
        <custom-dialog v-model="editedOpen" :persistent="true">
          <template v-slot:title>依頼追加</template>
          <template v-slot:body>
            <v-alert type="error" class="pa-0 ma-0" v-if="isError.reapply"
              >すでに再申請されています。
            </v-alert>
            <v-alert type="error" class="pa-0 ma-0" v-if="isError.file"
              >『 ファイル 』を入力してください。
            </v-alert>
            <v-alert type="error" class="pa-0 ma-0" v-if="isError.category"
              >『 カテゴリ 』を選択してください。
            </v-alert>
            <v-alert type="error" class="pa-0 ma-0" v-if="isError.route"
              >『 申請経路 』を入力してください。
            </v-alert>
            <v-alert type="error" class="pa-0 ma-0" v-if="isError.extra"
              >{{ isError.extraText }}
            </v-alert>
            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">カテゴリ</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-select
                    item-text="disp_name"
                    item-value="id"
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-bookmark'
                        : ''
                    "
                    :items="workflowTemplate"
                    v-model="editedEvent.template_id"
                    @change="category_change"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    hide-details="auto"
                  >
                    <template v-slot:label>
                      <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                        カテゴリ
                      </div>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <dynamic-work-flow
                ref="dynamicWorkflow"
                :rows="editRows"
                :data="editData"
                :readonly="false"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                :ismobile="ismobile"
                :useIcon="getDispStyle(config.dispStyle).useIcon"
                :useTitleLabel="getDispStyle(config.dispStyle).useTitleLabel"
              />
              <template v-if="useUserList">
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col :cols="12" class="pb-3">
                    <v-btn @click="useUserList = !useUserList"
                      >ユーザー一覧から選択する</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col
                    :cols="ismobile ? 12 : 4"
                    v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                  >
                    <p class="mb-0">申請経路</p>
                  </v-col>
                  <v-col
                    :cols="
                      getDispStyle(config.dispStyle).useTitleLabel || ismobile
                        ? 12
                        : 8
                    "
                  >
                    <v-select
                      item-text="route_name"
                      item-value="id"
                      :prepend-icon="
                        getDispStyle(config.dispStyle).useIcon
                          ? 'mdi-bookmark'
                          : ''
                      "
                      style="max-width: 300px"
                      :items="workflowRoute"
                      v-model="editedEvent.route_id"
                      @change="route_change"
                      :outlined="getDispStyle(config.dispStyle).outlined"
                      :dense="dense"
                      hide-details="auto"
                    >
                      <template v-slot:label>
                        <div
                          v-if="getDispStyle(config.dispStyle).useTitleLabel"
                        >
                          申請経路
                        </div>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col
                    :cols="ismobile ? 12 : 4"
                    v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                  >
                  </v-col>
                  <v-col
                    :cols="
                      getDispStyle(config.dispStyle).useTitleLabel || ismobile
                        ? 12
                        : 8
                    "
                  >
                    <div v-if="editedEvent.route">
                      <span
                        v-for="(item, index) in editedEvent.route"
                        :key="index"
                        >{{
                          usersList.find(item2 => item2.id === item).disp_name
                        }}
                        ->
                      </span>
                      <span v-if="editedEvent.route.length">完了</span>
                    </div>
                  </v-col>
                </v-row>
              </template>
              <template v-else>
                <v-row class="d-flex align-center py-1" no-gutters>
                  <v-col :cols="12">
                    <v-btn @click="useUserList = !useUserList"
                      >申請経路一覧から選択する</v-btn
                    >
                    <multi-user-select-list
                      :data="editedEvent.route"
                      :label="'申請経路順'"
                      :dispNumber="true"
                    />
                  </v-col>
                </v-row>
              </template>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">ファイル</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <file-input
                    ref="fileinput"
                    :params="{
                      label: getDispStyle(config.dispStyle).useTitleLabel
                        ? 'ファイル'
                        : ''
                    }"
                    :outlined="getDispStyle(config.dispStyle).outlined"
                    :dense="dense"
                    :prepend-icon="
                      getDispStyle(config.dispStyle).useIcon
                        ? 'mdi-paperclip'
                        : ''
                    "
                  />
                  <v-chip
                    v-for="(item, index) in editedEvent.files"
                    :key="index"
                    close
                    close-icon="mdi-close-outline"
                    @click:close="
                      editedEvent.files = editedEvent.files.filter(
                        n => n !== item
                      )
                    "
                    >{{ getFileName(item) }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn color="info" @click="endEdit" v-html="'登録'"> </v-btn>
            <v-btn color="accent" @click="cancelEdit"> キャンセル </v-btn>
          </template>
        </custom-dialog>

        <custom-dialog v-model="dispCounfig.dialogOpen">
          <template v-slot:title>表示絞込み</template>
          <template v-slot:body>
            <v-alert
              class="ma-0"
              type="error"
              v-if="dispCounfig.error_message.length > 0"
              dense
              >{{ dispCounfig.error_message }}
            </v-alert>
            <v-container>
              <v-row class="d-flex align-center py-1" no-gutters>
                <v-col
                  :cols="ismobile ? 12 : 4"
                  v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                >
                  <p class="mb-0">更新日</p>
                </v-col>
                <v-col
                  :cols="
                    getDispStyle(config.dispStyle).useTitleLabel || ismobile
                      ? 12
                      : 8
                  "
                >
                  <v-row>
                    <v-col
                      :cols="ismobile ? 12 : 4"
                      v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                    >
                      <p class="mb-0">カテゴリ</p>
                    </v-col>
                    <v-col
                      :cols="
                        getDispStyle(config.dispStyle).useTitleLabel || ismobile
                          ? 12
                          : 8
                      "
                    >
                      <v-select
                        item-text="disp_name"
                        item-value="id"
                        multiple
                        :prepend-icon="
                          getDispStyle(config.dispStyle).useIcon
                            ? 'mdi-bookmark'
                            : ''
                        "
                        :items="workflowTemplate"
                        v-model="dispCounfig.template_id_edit"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div
                            v-if="getDispStyle(config.dispStyle).useTitleLabel"
                          >
                            カテゴリ
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="
                          getDispStyle(config.dispStyle).useIcon
                            ? 'mdi-calendar'
                            : ''
                        "
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.start_date_edit"
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div
                            v-if="getDispStyle(config.dispStyle).useTitleLabel"
                          >
                            更新日（開始日）
                          </div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="dispCounfig.start_date_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        class="mt-4"
                        :prepend-icon="
                          getDispStyle(config.dispStyle).useIcon ? '' : ''
                        "
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        v-model="dispCounfig.end_date_edit"
                        :readonly="true"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div
                            v-if="getDispStyle(config.dispStyle).useTitleLabel"
                          >
                            更新日（終了日）
                          </div>
                        </template>
                        <template v-slot:append-outer>
                          <date-picker v-model="dispCounfig.end_date_edit" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      :cols="ismobile ? 12 : 4"
                      v-show="!getDispStyle(config.dispStyle).useTitleLabel"
                    >
                      <p class="mb-0">申請者</p>
                    </v-col>
                    <v-col
                      :cols="
                        getDispStyle(config.dispStyle).useTitleLabel || ismobile
                          ? 12
                          : 8
                      "
                    >
                      <v-select
                        item-text="disp_name"
                        item-value="id"
                        multiple
                        :prepend-icon="
                          getDispStyle(config.dispStyle).useIcon
                            ? 'mdi-bookmark'
                            : ''
                        "
                        :items="usersList.filter(x => x.enable)"
                        v-model="dispCounfig.user_id_edit"
                        :outlined="getDispStyle(config.dispStyle).outlined"
                        :dense="dense"
                        hide-details="auto"
                      >
                        <template v-slot:label>
                          <div
                            v-if="getDispStyle(config.dispStyle).useTitleLabel"
                          >
                            申請者
                          </div>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:footer>
            <v-btn color="accent" @click="dispConfigDialogClose()">
              閉じる
            </v-btn>
          </template>
        </custom-dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import DatePicker from '../components/DatePicker';
import FileInput from '../components/FileInput2.vue';
import CustomDialog from '../components/CustomDialog.vue';
import dateformat from '../functions/DateFormat';
import DynamicWorkFlow from '../components/DynamicWorkflow.vue';
import MultiUserSelectList from '../components/MultiUserSelectList.vue';
import dateUtils from '../functions/DateUtils';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';

export default {
  components: {
    FileInput,
    DynamicWorkFlow,
    MultiUserSelectList,
    CustomDialog,
    DatePicker
  },
  data: () => ({
    name: 'workflow2',
    path: '/workflow2',
    config: {},
    dense: true,
    ismobile: false,
    headers: [],
    dispmode: 0,
    statusTextMap: ['進行中', '却下', '完了'],
    workflowStatus: {
      apply: { id: 0, disp_name: '進行中', color: 'blue' },
      reject: { id: 1, disp_name: '却下', color: 'red' },
      accept: { id: 2, disp_name: '完了', color: 'green' }
    },
    listitem: [],
    fileindexlist: [],
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    dialogLeft: 0,
    disp_cols: 2,
    data_cols: 10,
    isError: {
      reapply: false,
      file: false,
      category: false,
      route: false,
      extra: false,
      extraText: ''
    },
    selectedOpen: false,
    selectedFullScreen: false,
    selectedEvent: {},
    selectedRows: [],
    selectedData: [],
    editedOpen: false,
    editedFullScreen: false,
    editedEvent: { route: [] },
    editRows: [],
    editData: [],
    acceptData: { message: '', route_users: [], user_id: -1 },
    useUserList: true,
    uploadCount: 0,
    uploadStartFile: [],
    uploadEndFile: [],
    dispCounfig: {
      dialogOpen: false,
      error_message: '',
      start_date: '',
      end_date: '',
      template_id: [],
      user_id: [],
      start_date_edit: '',
      end_date_edit: '',
      template_id_edit: [],
      user_id_edit: []
    }
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    $route(to, from) {
      // ルートの変更の検知...
      console.log({ to, from });
      if (to.query.id) {
        this.dispWorkFlowItem(to.query.id);
      }
    }
  },
  // updated: function () {
  //   this.$nextTick(function () {
  //     this.doScrollTop();
  //   });
  // },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      filedata: state => state.fileData.filedata,
      userConfig: state => state.userConfig.userconfig,
      workflowTemplate: state => state.workflowTemplate.workflowTemplate,
      workflowTemplateRow: state =>
        state.workflowTemplateRow.workflowTemplateRow,
      workflow: state => state.workflow.workflow2,
      workflowMap: state => state.workflow.workflowMap,
      workflowRoute: state => state.workflowRoute.workflowRoute
    })
  },
  mixins: [token, fileOperation, inputStyleUtils],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchWorkflowTemplate',
      'fetchWorkflowTemplateRow',
      'fetchWorkflowRoute',
      'fetchWorkflow',
      'fetchWorkflowItem',
      'CreateWorkflowNewIndex',
      'updateWorkflow',
      'updateWorkflowWithFileData',
      'insertApplyWorkflow',
      'insertReapplyWorkflow',
      'deleteWorkflow',
      'fetchWorkflowMap',
      'fetchWorkflowMapItem',
      'upsertWorkflowMaps',
      'fetchFileData',
      'downloadFile'
    ]),
    init() {
      let p = [];
      p.push(this.fetchWorkflowTemplate());
      p.push(this.fetchWorkflowTemplateRow());
      p.push(this.fetchWorkflowRoute());

      Promise.all(p).then(() => {
        let config = this.userConfig.find(
          item => item.user_id === this.getUserId()
        );

        this.config[this.getItemsPerPage()] =
          config[this.getItemsPerPage()] || (this.isMainPage() ? 10 : 5);

        this.config.dispStyle = config.dispStyle || 1;

        this.dispCounfig.start_date = dateformat.format(
          dateUtils.addMonth(dateUtils.getToday(), -1),
          'YYYY-MM-DD'
        );
        this.dispCounfig.end_date = '';
        this.dispCounfig.template_id = [];
        this.dispCounfig.user_id = [];

        this.dispWorkflowData();

        if (this.$route.query.id) {
          this.dispWorkFlowItem(this.$route.query.id);
        }
      });
    },

    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);

      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;
      this.ismobile = window.innerWidth < 600;

      this.headers = this.getHeaders();
      if (this.isMainPage()) {
        this.disp_cols = 2;
        this.data_cols = 10;
      } else {
        this.disp_cols = 12;
        this.data_cols = 12;
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    getItemsPerPage() {
      return `${this.name}_items_per_page_${
        this.isMainPage() ? 'main' : 'sub'
      }`;
    },
    getDispmodeList() {
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.VIEW
      );
      // const otherPerMission = this.getPermission(
      //   this.TOKEN_FEATURE.WORKFLOW,
      //   this.TOKEN_OWNER.OTHER,
      //   this.TOKEN_ACTION.VIEW
      // );
      const otherPerMission = false;
      const ret = [];
      if (mePermission) {
        ret.push({ id: 0, disp_name: '受信した依頼' });
        ret.push({ id: 1, disp_name: '送信した依頼' });
      }
      if (
        otherPerMission ||
        this.usersList.find(item => item.id === this.getUserId())?.admin
      ) {
        //とりあえず管理者は全部見えるようにする
        ret.push({ id: 2, disp_name: '全ての依頼' });
      }
      return ret;
    },
    getHeaders() {
      const title = {
        text: 'タイトル',
        value: 'title'
      };
      const status = {
        text: '状況',
        value: 'statusId',
        align: 'center',
        width: '100px'
      };
      const insertuser = {
        text: '申請者',
        value: 'insertuser',
        width: '120px'
      };
      const updateuser = {
        text: '決裁者',
        value: 'dispUser',
        width: '120px'
      };
      const inserttime = {
        text: '申請日',
        value: 'inserttime',
        align: 'end',
        width: '150px'
      };
      if (this.cardSize.x < 400) {
        return [title, status];
      } else if (this.cardSize.x < 600) {
        if (this.dispmode === 0) {
          return [title, status, insertuser];
        } else {
          return [title, status, updateuser];
        }
      } else if (this.cardSize.x < 1000) {
        return [title, status, insertuser, updateuser, inserttime];
      } else {
        return [title, status, insertuser, updateuser, inserttime];
      }
    },
    canEditEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.EDIT
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      return result;
    },
    canReapply(event) {
      //再申請可能か
      let result = false;
      if (!event.insertuser) return false;

      //作成者のみ
      result = event.insertuser === this.getUserId();

      //すでに再申請が行われている場合はNG
      if (
        this.workflow.find(
          wfItem =>
            wfItem.workflow_id === event.workflow_id &&
            wfItem.workflow_index === event.workflow_index + 1
        )
      ) {
        result = false;
      }
      //承認待ちの場合はNG
      if (this.getNextAuthUser(event) > 0) {
        //次の承認者が取得できる
        result = false;
      }
      //承認済みは再申請不可
      if (this.getWfStatus(event)?.id === this.workflowStatus.accept.id) {
        //承認済み
        result = false;
      }

      return result;
    },
    canDeleteEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.DELETE
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.DELETE
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }

      //承認が始まっていたら削除不可
      let findmaps = this.filterWorkflowMaps(this.workflowMap, event);
      result = result && findmaps.length === 0;

      return result;
    },

    showEvent(eventData) {
      this.selectedEvent = eventData;

      let rows = this.getTempRows(this.selectedEvent.template_id);
      let data = this.getTempData(rows, this.selectedEvent.data);

      this.selectedRows = JSON.parse(JSON.stringify(rows));
      this.selectedData = JSON.parse(JSON.stringify(data));
      this.acceptData = this.createAcceptData(this.selectedEvent);

      this.selectedOpen = true;
      console.log(data);
    },
    downloadCountUp(id) {
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '';
      this.downloadFile(item).then(res => {
        // 取得したファイルをダウンロードできるようにする
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', item.originalname);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

      // const data = this.selectedEvent;
      // this.updateWorkflow(data).then(() => {
      //   //表示するワークフローの一覧を取得
      //   this.dispWorkflowData();
      // });
    },
    startEdit() {
      const data = {
        id: -1,
        workflow_id: -1,
        workflow_index: 1,
        template_id: this.workflowTemplate[0].id,
        title: '',
        data: '[]',
        state: '進行中',
        files: [],
        route_id: -1,
        route: [],
        insertuser: this.getUserId(),
        inserttime: new Date(),
        updateuser: this.getUserId(),
        updatetime: new Date()
      };

      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }
      this.selectedOpen = false;
      this.selectedEvent = {};
      this.editRows = [];
      this.editData = [];

      //ルート選択モードの場合承認経路の初期設定を行う
      if (this.useUserList) {
        let route = this.getDefRoute(this.editedEvent.template_id);
        if (route) {
          data.route_id = route.id;
          data.route = route.route.slice();
        }
      }

      this.editedOpen = true;
      this.editedEvent = data;
    },
    category_change() {
      let rows = this.getTempRows(this.editedEvent.template_id);
      let data = this.getTempData(rows, '[]');

      this.editRows = JSON.parse(JSON.stringify(rows));
      this.editData = JSON.parse(JSON.stringify(data));

      //ルート選択モードの場合承認経路の初期設定を行う
      if (this.useUserList) {
        let route = this.getDefRoute(this.editedEvent.template_id);
        if (route) {
          this.editedEvent.route_id = route.id;
          this.editedEvent.route = route.route.slice();
        }
      }
    },
    getTempRows(template_id) {
      return this.workflowTemplateRow.filter(
        item => item.template_id === template_id
      );
    },
    getTempData(rows, dataString) {
      let newData = [];
      let data = null;
      try {
        data = JSON.parse(dataString);
        if (!Array.isArray(data)) {
          data = [];
        }
      } catch (error) {
        //
        console.log(error);
      }
      for (var i = 0; i < rows.length; i++) {
        let row = rows[i];
        let item = data.find(x => x.id === row.id);
        if (!item) {
          item = { id: row.id };
        }

        let defItem = this.createDefDataItem(row);

        if (item.value == null) {
          item.value = defItem.value;
        }
        if (item.value2 == null) {
          item.value2 = defItem.value2;
        }
        newData.push(item);
      }
      return newData;
    },
    createAcceptData(wfItem) {
      let data = { message: '', route_users: [], user_id: -1 };

      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
      //申請者
      let insUser = JSON.parse(
        JSON.stringify(
          this.usersList.find(item => item.id === wfItem.insertuser)
        )
      );
      insUser.disp_name = '申請者';
      insUser.id = -1;
      data.route_users.push(insUser);
      findmaps.forEach(map => {
        if (!map.reject) {
          data.route_users.push(
            this.usersList.find(item => item.id === map.user_id)
          );
        }
      });
      data.user_id = data.route_users[0].id;

      let rejectMap = this.getRejectMap(this.selectedEvent);
      if (rejectMap) {
        data.message = rejectMap.message;
      }

      return data;
    },
    createDefDataItem(row) {
      let today = dateformat.format(dateUtils.getToday(), 'YYYY-MM-DD');
      let nowTime = dateformat.format(dateUtils.getNow(), 'HH:mm');
      let tomorrow = dateformat.format(dateUtils.getTomorrow(), 'YYYY-MM-DD');
      let yesterday = dateformat.format(dateUtils.getYesterday(), 'YYYY-MM-DD');
      let lastSunday = dateformat.format(
        dateUtils.getLastSundy(),
        'YYYY-MM-DD'
      );
      let lastWeekMonday = dateformat.format(
        dateUtils.getLastWeekMonday(),
        'YYYY-MM-DD'
      );
      let nextSaturday = dateformat.format(
        dateUtils.getNextSaturday(),
        'YYYY-MM-DD'
      );
      let nextMonday = dateformat.format(
        dateUtils.getNextMonday(),
        'YYYY-MM-DD'
      ); //日付は特別実装
      if (row.type === 'date_span') {
        if (row.default_value === 'last_week') {
          return {
            value: lastWeekMonday,
            value2: lastSunday
          };
        } else if (row.default_value === 'tomorrow') {
          return {
            value: tomorrow,
            value2: tomorrow
          };
        } else if (row.default_value === 'yesterday') {
          return {
            value: yesterday,
            value2: yesterday
          };
        } else if (row.default_value === 'next_saturday') {
          return {
            value: nextSaturday,
            value2: nextSaturday
          };
        } else if (row.default_value === 'next_monday') {
          return {
            value: nextMonday,
            value2: nextMonday
          };
        } else if (row.default_value === 'today') {
          return {
            value: today,
            value2: today
          };
        } else {
          return {
            value: today,
            value2: today
          };
        }
      } else if (row.type === 'DatePicker') {
        if (row.default_value === 'last_week') {
          return {
            value: lastWeekMonday,
            value2: false
          };
        } else if (row.default_value === 'tomorrow') {
          return {
            value: tomorrow,
            value2: false
          };
        } else if (row.default_value === 'yesterday') {
          return {
            value: yesterday,
            value2: false
          };
        } else if (row.default_value === 'next_saturday') {
          return {
            value: nextSaturday,
            value2: false
          };
        } else if (row.default_value === 'next_monday') {
          return {
            value: nextMonday,
            value2: false
          };
        } else if (row.default_value === 'today') {
          return {
            value: today,
            value2: false
          };
        } else {
          return {
            value: today,
            value2: false
          };
        }
      } else if (row.type === 'TimePicker') {
        if (row.default_value === 'now') {
          return {
            value: nowTime,
            value2: nowTime
          };
        } else if (row.default_value !== '') {
          return {
            value: row.default_value,
            value2: row.default_value
          };
        } else {
          return {
            value: nowTime,
            value2: nowTime
          };
        }
      } else if (row.type === 'date_month') {
        return {
          value: dateUtils.getYear(dateUtils.getToday()),
          value2: dateUtils.getMonth(dateUtils.getToday())
        };
      } else if (row.type === 'VSelect') {
        //スピナーは数値で処理する必要がある
        return { value: parseInt(row.default_value), value2: '' };
      } else {
        return { value: row.default_value, value2: '' };
      }
    },
    route_change() {
      console.log([this.editedEvent.route_id]);
      let selectRoute = this.workflowRoute.find(
        item => item.id === this.editedEvent.route_id
      );
      if (selectRoute) {
        this.editedEvent.route = selectRoute.route.slice();
      }
    },
    getDefRoute(category_id) {
      const user_id = this.getUserId();
      const group_id = this.usergroup
        .filter(x => x.user_id.find(x => x === user_id))
        .map(x => x.id);

      //defaultの承認経路の取得
      let route = null;
      if (category_id > 0) {
        //カテゴリとユーザーが一致するもの
        route = this.workflowRoute.find(
          x =>
            x.def_category_id.find(x => x === category_id) &&
            x.def_user_id.find(x => x === user_id)
        );
        if (!route) {
          //カテゴリとグループが一致するもの
          route = this.workflowRoute.find(
            x =>
              x.def_category_id.find(x => x === category_id) &&
              x.def_group_id.find(x => group_id.find(y => y === x))
          );
        }
      }
      if (!route) {
        //カテゴリ未設定でユーザーが一致するもの
        route = this.workflowRoute.find(
          x =>
            (!x.def_category_id || x.def_category_id.length === 0) &&
            x.def_user_id.find(x => x === user_id)
        );
      }
      if (!route) {
        //カテゴリ未設定でグループが一致するもの
        route = this.workflowRoute.find(
          x =>
            (!x.def_category_id || x.def_category_id.length === 0) &&
            x.def_group_id.find(x => group_id.find(y => y === x))
        );
      }
      return route;
    },
    startEditing() {
      this.errrorReset();
      if (this.$refs.fileinput) {
        this.$refs.fileinput.fileClear();
      }

      this.editedEvent = JSON.parse(JSON.stringify(this.selectedEvent));
      this.editedEvent.workflow_index += 1;
      this.editedEvent.id = -1; //idを初期か

      this.editRows = this.getTempRows(this.editedEvent.template_id);
      this.editData = this.getTempData(this.editRows, this.editedEvent.data);

      //申請経路が選択済みのものなら申請経路選択とする
      let selectRoute = this.workflowRoute.find(
        item => item.id === this.editedEvent.route_id
      );
      if (selectRoute) {
        this.useUserList =
          JSON.stringify(this.editedEvent.route) ===
          JSON.stringify(selectRoute.route);
      } else {
        this.useUserList = false;
      }
      this.selectedOpen = false;
      this.editedOpen = true;
    },
    deleteData() {
      //削除確認
      if (!window.confirm(`この依頼を削除してよろしいですか？`)) return;

      let results = [];
      let promise = null;

      const fileIds = this.selectedEvent.files;
      // workflowデータおよびworkflowmapデータを付随するファイルデータとともに削除
      if (promise == null) {
        promise = this.deleteWorkflow({
          workflow: this.selectedEvent,
          fileIds
        });
      } else {
        promise = promise.then(result => {
          results.push(result);
          return this.deleteWorkflow({
            workflow: this.selectedEvent,
            fileIds
          });
        });
      }

      promise
        .then(() => {
          //表示するスケジュールの一覧を取得
          this.dispWorkflowData();
          this.selectedOpen = false;
          this.editedOpen = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      let extraErrors = this.$refs.dynamicWorkflow.inputCheck();
      if (extraErrors.length > 0) {
        this.isError.extra = true;
        this.isError.extraText = extraErrors[0];
      }
      //カテゴリ選択チェック
      this.isError.category = this.editedEvent.template_id === 1;

      //申請経路チェック
      this.isError.route = this.editedEvent.route.length === 0;

      // let files = this.$refs.fileinput.files;
      // //ファイル数チェック
      // this.isError.file = files.length <= 0;

      for (var key in this.isError) {
        if (this.isError[key]) {
          this.doScrollTop();
          return false;
        }
      }
      return true;
    },
    dispConfigDialogOpen() {
      this.dispCounfig.start_date_edit = this.dispCounfig.start_date;
      this.dispCounfig.end_date_edit = this.dispCounfig.end_date;
      this.dispCounfig.template_id_edit = [...this.dispCounfig.template_id];
      this.dispCounfig.user_id_edit = [...this.dispCounfig.user_id];
      this.dispCounfig.dialogOpen = true;
    },
    dispConfigDialogClose() {
      this.dispCounfig.error_message = '';

      if (
        this.dispCounfig.start_date_edit.length > 0 &&
        this.dispCounfig.end_date_edit.length > 0
      ) {
        if (
          new Date(dateUtils.getDate(this.dispCounfig.start_date_edit)) >
          new Date(dateUtils.getDate(this.dispCounfig.end_date_edit))
        ) {
          this.dispCounfig.error_message =
            '『 終了日 』は『 開始日 』以降の日付を指定してください。';
          return;
        }
      }
      this.dispCounfig.dialogOpen = false;
      this.dispCounfig.start_date = this.dispCounfig.start_date_edit;
      this.dispCounfig.end_date = this.dispCounfig.end_date_edit;
      this.dispCounfig.template_id = [...this.dispCounfig.template_id_edit];
      this.dispCounfig.user_id = [...this.dispCounfig.user_id_edit];
      this.dispWorkflowData();
    },

    doScrollTop() {
      const elements = document.getElementsByClassName('v-dialog--active');
      if (!elements || !elements.length) {
        // 要素が取得できなかった場合は終了
        return;
      }
      elements[0].scrollTop = 0;
    },
    endEdit() {
      this.errrorReset();
      //入力チェック
      if (!this.inputCheck()) return;
      //登録前確認

      //登録するworkflow_indexがすでに登録されていないか確認する
      this.CreateWorkflowNewIndex({
        workflow_id: this.editedEvent.workflow_id
      }).then(result => {
        const workflow_index = result.data.workflow_index;

        if (this.editedEvent.workflow_index === workflow_index) {
          //登録処理を呼び出す
          this.registData()
            .then(() => {
              //表示するスケジュールの一覧を取得
              this.dispWorkflowData();
              this.editedOpen = false;
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          //表示するスケジュールの一覧を取得
          this.dispWorkflowData();
          //画面更新を促す
          this.isError.reapply = true;
          this.doScrollTop();
        }
      });
    },
    async registData() {
      let files = this.$refs.fileinput.files;

      let formData = new FormData();
      if (files.length > 0) {
        files.forEach(file => {
          formData.append('files', file);
        });
      }

      const data = {
        template_id: this.editedEvent.template_id,
        workflow_id: this.editedEvent.workflow_id,
        workflow_index: this.editedEvent.workflow_index,
        title: this.workflowTemplate.find(
          item => item.id === this.editedEvent.template_id
        ).disp_name,
        data: JSON.stringify(this.editData),
        state: '進行中',
        files: Array.from(this.editedEvent.files),
        route_id: this.editedEvent.route_id,
        route: this.editedEvent.route,
        insertuser: this.editedEvent.insertuser,
        inserttime: this.editedEvent.inserttime,
        updateuser: this.getUserId(),
        updatetime: new Date()
      };

      if (this.editedEvent.id != -1 || this.editedEvent.workflow_id != -1) {
        const originalFileIds = this.selectedEvent.files; // 編集前のfileIds
        const editedFileIds = this.editedEvent.files; // 編集後のfileIds
        // 編集の結果、削除すべきファイルのid
        const fileIdsToDelete = originalFileIds.filter(
          id => editedFileIds.indexOf(id) === -1
        );
        // 編集の結果、残しておくべきファイルのid
        const noDeletedFileIds = originalFileIds.filter(
          id => editedFileIds.indexOf(id) !== -1
        );

        // リクエストのbodyに以下のデータを追加
        data.fileIdsToDelete = fileIdsToDelete;
        data.noDeletedFileIds = noDeletedFileIds;
        data.id = this.editedEvent.id; // workflowデータのid
      }

      if (this.editedEvent.id != -1) {
        //既存ワークフローの更新
        formData.append('data', JSON.stringify(data));
        await this.updateWorkflowWithFileData(formData);
      } else {
        if (this.editedEvent.workflow_id != -1) {
          //新規ワークフロー（再申請）
          formData.append('data', JSON.stringify(data));
          await this.insertReapplyWorkflow(formData);
        } else {
          formData.append('data', JSON.stringify(data));
          //新規ワークフロー（新規申請）
          await this.insertApplyWorkflow(formData);
        }
      }
    },
    cancelEdit() {
      this.$refs.fileinput.fileClear();
      this.editedOpen = false;
    },

    doReject() {
      let promise = null;
      //ワークフロー
      let targetWfItem = this.selectedEvent;
      //承認者
      let accept_user_id = this.getNextAuthUser(targetWfItem);
      let reject_user_id = this.acceptData.user_id;

      //ログインユーザーと承認者が異なる
      if (accept_user_id !== this.getUserId()) {
        if (
          !window.confirm(
            `承認者はログインユーザーではありません。続行しますか？`
          )
        )
          return;
      }

      let findmaps = this.filterWorkflowMaps(this.workflowMap, targetWfItem);

      let targetMap = findmaps.find(
        map =>
          map.workflow_id === targetWfItem.workflow_id &&
          map.workflow_index === targetWfItem.workflow_index &&
          map.user_id === accept_user_id
      );

      //却下する
      let mapData = null;
      if (targetMap) {
        mapData = JSON.parse(JSON.stringify(targetMap));
        mapData.accept = false;
        mapData.reject = true;
        mapData.message = this.acceptData.message;
        mapData.updateuser = this.getUserId();
        mapData.updatetime = new Date();
      } else {
        mapData = {
          workflow_id: targetWfItem.workflow_id,
          workflow_index: targetWfItem.workflow_index,
          user_id: accept_user_id,
          accept: false,
          reject: true,
          message: this.acceptData.message,
          insertuser: this.getUserId(),
          inserttime: new Date(),
          updateuser: this.getUserId(),
          updatetime: new Date()
        };
      }

      //差し戻し先が申請者ではない場合、承認結果を再入力モードにする
      const reMaps = [];

      if (reject_user_id !== -1) {
        let reject = false;
        let findmaps = this.filterWorkflowMaps(this.workflowMap, targetWfItem);
        findmaps.forEach(map => {
          //差し戻し先と一致したらそれ以降をrejectする
          reject = reject || map.user_id === reject_user_id;

          let newMap = JSON.parse(JSON.stringify(map));
          newMap.reject = reject;
          reMaps.push(newMap);
        });
      } else {
        //申請者への差し戻しの場合、却下とする
        targetWfItem.state = '否認';
        mapData.reject = false; //

        //道中のreject
        let findmaps = this.filterWorkflowMaps(this.workflowMap, targetWfItem);
        findmaps.forEach(map => {
          let newMap = JSON.parse(JSON.stringify(map));
          newMap.reject = false;
          newMap.updateuser = this.getUserId();
          newMap.updatetime = new Date();

          reMaps.push(newMap);
        });
      }
      targetWfItem.updateuser = mapData.updateuser;
      targetWfItem.updatetime = mapData.updatetime;

      //更新対象に追加
      reMaps.push(mapData);

      promise = this.updateWorkflow(targetWfItem);

      promise = promise.then(() => this.upsertWorkflowMaps(reMaps));

      promise
        .then(() => {
          //表示するスケジュールの一覧を取得
          this.dispWorkflowData();
          this.editedOpen = false;
          this.selectedOpen = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    doAccept() {
      let promise = null;
      //ワークフロー
      let targetWfItem = this.selectedEvent;
      //承認者
      let accept_user_id = this.getNextAuthUser(targetWfItem);

      //ログインユーザーと承認者が異なる
      if (accept_user_id !== this.getUserId()) {
        if (
          !window.confirm(
            `承認者はログインユーザーではありません。続行しますか？`
          )
        )
          return;
      }
      let findmaps = this.filterWorkflowMaps(this.workflowMap, targetWfItem);

      let targetMap = findmaps.find(
        map =>
          map.workflow_id === targetWfItem.workflow_id &&
          map.workflow_index === targetWfItem.workflow_index &&
          map.user_id === accept_user_id
      );

      //承認する
      let mapData = null;
      if (targetMap) {
        mapData = JSON.parse(JSON.stringify(targetMap));
        mapData.accept = true;
        mapData.reject = false;
        mapData.message = this.acceptData.message;
        mapData.updateuser = this.getUserId();
        mapData.updatetime = new Date();
      } else {
        mapData = {
          workflow_id: targetWfItem.workflow_id,
          workflow_index: targetWfItem.workflow_index,
          user_id: accept_user_id,
          accept: true,
          reject: false,
          message: this.acceptData.message,
          insertuser: this.getUserId(),
          inserttime: new Date(),
          updateuser: this.getUserId(),
          updatetime: new Date()
        };
      }

      if (
        targetWfItem.route[targetWfItem.route.length - 1] === accept_user_id
      ) {
        //最後の承認者
        targetWfItem.state = '完了';
      } else {
        //途中の承認者
        targetWfItem.state = '進行中';
      }
      targetWfItem.updateuser = mapData.updateuser;
      targetWfItem.updatetime = mapData.updatetime;

      promise = this.upsertWorkflowMaps([mapData]);
      promise = promise.then(() => this.updateWorkflow(targetWfItem));
      promise
        .then(() => {
          //表示するスケジュールの一覧を取得
          this.dispWorkflowData();
          this.editedOpen = false;
          this.selectedOpen = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    dispWorkflowData() {
      this.setDispMode(this.dispmode);
    },
    setDispMode(dispmode) {
      const data = {
        dispmode,
        start_date: this.dispCounfig.start_date,
        end_date: this.dispCounfig.end_date,
        template_id: this.dispCounfig.template_id,
        user_id: this.dispCounfig.user_id
      };
      console.log(data);

      Promise.all([
        this.fetchWorkflow(data),
        // this.fetchWorkflowMap(),
        this.fetchFileData()
      ]).then(() => {
        this.listitem = this.workflow
          .filter(x => {
            return this.isDispWorkflow(x, this.dispmode);
          })
          .map(x => {
            x['statusId'] = this.getWfStatus(x).id;
            x['dispUser'] = this.getUserName(this.getDispAuthUser(x));
            return x;
          });
        //検索設定の保存
        this.saveConfig();
      });
    },
    isDispWorkflow(wfItem, dispmode) {
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.VIEW
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.WORKFLOW,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.VIEW
      );

      //ワークフローを表示するか
      if (dispmode === 0) {
        //受信した依頼
        //承認者が自分
        if (this.getNextAuthUser(wfItem) === this.getUserId()) {
          return mePermission;
        }
        //申請経路を取得
        let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
        //承認済みの経路に自分がいる
        const exist = findmaps.find(
          map => map.user_id === this.getUserId() && !map.reject
        );
        if (exist) {
          return mePermission;
        }
      } else if (dispmode === 1) {
        //送信した依頼

        //申請者が自分
        if (wfItem.insertuser === this.getUserId()) {
          return mePermission;
        }
      } else {
        //全ての依頼
        return (
          otherPerMission ||
          this.usersList.find(item => item.id === this.getUserId())?.admin
        );
      }
      return false;
    },
    async dispWorkFlowItem(id) {
      try {
        await this.$router.push(this.name);
        const workflowResult = await this.fetchWorkflowItem({ id: id });
        const wfItem = workflowResult.data;

        if (this.filterWorkflowMaps(this.workflowMap, wfItem).length <= 0) {
          const workFlowMap = await this.fetchWorkflowMapItem({
            workflow_id: wfItem.workflow_id,
            workflow_index: wfItem.workflow_index
          });
          this.workflowMap.push(...workFlowMap.data);
        }

        this.showEvent(wfItem);
      } catch (err) {
        console.log(err);
      }
    },
    filterWorkflowMaps(workflowMap, wfItem) {
      return workflowMap.filter(
        mpItem =>
          mpItem.workflow_id === wfItem.workflow_id &&
          mpItem.workflow_index === wfItem.workflow_index
      );
    },

    saveConfig() {
      //選択状態を保存する
      let promise = this.fetchUserConfig();

      //現状保存するものがない
      // promise = promise
      //   .then(() => {
      //     let config = this.userConfig.find(
      //       item => item.user_id === this.getUserId()
      //     );

      //     if (!config) {
      //       config = {};
      //       config.user_id = this.getUserId();
      //     }
      //     return this.upsertUserConfig(config);
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
      promise.catch(err => {
        console.log(err);
      });
      return promise;
    },
    getDownloadLink(id) {
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '';
      return process.env.VUE_APP_API_URL_FILE + '/download/' + item.filename;
    },

    getFileName(id) {
      if (!id) return '---';
      let item = this.filedata.find(item => item.id === id);
      if (!item) return '---';
      return item.originalname + '(' + this.fileSizeFormat(item.filesize) + ')';
    },
    getDirectryName(id) {
      if (!id) return '---';
      let item = this.directories.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getUserName(id) {
      if (!id) return '---';
      let item = this.usersList.find(item => item.id === id);
      if (!item) return '---';
      return item.disp_name;
    },
    getDateFormat(date) {
      return dateformat.autoFormat(date);
    },
    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    },
    getAcceptMap(wfItem, user_id) {
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);

      let map = findmaps.find(map => map.user_id === user_id);
      return map;
    },
    getAcceptMessage(wfItem, user_id) {
      let map = this.getAcceptMap(wfItem, user_id);
      if (map) {
        return map.message;
      } else {
        return '';
      }
    },
    getAcceptTime(wfItem, user_id) {
      let map = this.getAcceptMap(wfItem, user_id);
      if (map) {
        return this.fullDateFormat(map.updatetime);
      } else {
        return '';
      }
    },
    getStatusColor(wfItem) {
      return this.getWfStatus(wfItem).color;
    },
    getStatusText(wfItem) {
      return this.getWfStatus(wfItem).disp_name;
    },
    getWfStatus(wfItem) {
      if (this.getNextAuthUser(wfItem) > 0) {
        //進行中
        return this.workflowStatus.apply;
      } else {
        //申請経路を取得
        let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
        let lastMap = findmaps[findmaps.length - 1];
        if (lastMap.accept) {
          //最終承認者が承認
          return this.workflowStatus.accept;
        } else {
          //最終承認者が却下
          return this.workflowStatus.reject;
        }
      }
    },

    //最終決裁者取得（表示用）
    getDispAuthUser(wfItem) {
      let route = JSON.parse(JSON.stringify(wfItem.route));
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);

      //決裁情報がない
      if (findmaps.length === 0) {
        //最初のユーザー
        return route[0];
      }

      //最終決裁状況が否決
      let lastMap = findmaps[findmaps.length - 1];
      if (!lastMap.accept && !lastMap.reject) {
        //ユーザーなし
        return -1;
      }

      for (let i = 0; i < route.length; i++) {
        //承認状態の取得
        let map = findmaps.find(map => map.user_id === route[i]);
        if (!map) {
          //mapが存在しない場合はそのユーザーが次の承認者
          return route[i];
        }
        if (map.reject) {
          //reject指定されている場合はそのユーザー
          return route[i];
        }
        if (i === route.length - 1) {
          if (map.accept) {
            //決裁されている場合は決裁ユーザー
            return route[i];
          }
        }
      }
      //いずれも一致しない場合は拒否
      return -1;
    },
    //決裁者取得
    getNextAuthUser(wfItem) {
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
      //未承認の場合最初のユーザー
      if (findmaps.length === 0) {
        return wfItem.route[0];
      }
      //最後の要素が申請者への差し戻しの場合、次の承認者なし
      let lastMap = findmaps[findmaps.length - 1];
      if (!lastMap.accept && !lastMap.reject) return -1;

      //コピーする
      let route = JSON.parse(JSON.stringify(wfItem.route));

      //承認済ではない承認者を探す
      for (let i = 0; i < route.length; i++) {
        if (findmaps.find(map => map.user_id === route[i] && !map.reject)) {
          route[i] = -1;
        }
      }
      for (let i = 0; i < route.length; i++) {
        if (route[i] > 0) {
          return route[i]; //次の決裁者を返す
        }
      }
      return -1; //全て承認済
    },
    //再決裁時の決裁者初期メッセージ
    getRejectMap(wfItem) {
      let findmaps = this.filterWorkflowMaps(this.workflowMap, wfItem);
      //未承認の場合undefined
      if (findmaps.length === 0) {
        return undefined;
      }
      //コピーする
      let route = JSON.parse(JSON.stringify(wfItem.route));

      //承認済ではない承認者を探す
      for (let i = 0; i < route.length; i++) {
        let map = findmaps.find(map => map.user_id === route[i]);
        if (map) {
          //否決されたものを返す
          if (map.reject) {
            return map;
          }
        }
      }
      return undefined; //全て承認済
    },
    isNextAuthUser(wfItem, user_id) {
      return this.getNextAuthUser(wfItem) === user_id;
    },
    getAcceptTitle(wfItem, user_id) {
      if (this.isNextAuthUser(wfItem, user_id)) {
        return '確認中';
      } else {
        let map = this.getAcceptMap(wfItem, user_id);
        if (map) {
          return map.accept ? '承認' : '否認';
        }
        return '';
      }
    },
    getDispConfigText() {
      const result = [];
      if (this.dispCounfig.template_id.length > 0) {
        result.push(
          ' カテゴリ：' +
            this.dispCounfig.template_id
              .map(x => this.workflowTemplate.find(y => y.id == x).disp_name)
              .join()
        );
      }
      if (
        this.dispCounfig.start_date != '' ||
        this.dispCounfig.end_date != ''
      ) {
        result.push(
          '開始日：' +
            (this.dispCounfig.start_date == ''
              ? '(なし)'
              : this.dispCounfig.start_date)
        );
        result.push(
          '終了日：' +
            (this.dispCounfig.end_date == ''
              ? '(なし)'
              : this.dispCounfig.end_date)
        );
      }
      if (this.dispCounfig.user_id.length > 0) {
        result.push(
          ' 申請者：' +
            this.dispCounfig.user_id
              .map(x => this.usersList.find(y => y.id == x).disp_name)
              .join()
        );
      }
      if (result.length == 0) {
        result.push('全て');
      }

      return result.join(' ');
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
    
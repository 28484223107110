<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-main>
      <v-card width="400px" class="mx-auto mt-5">
        <v-card-title>
          <v-img
            class="shrink mr-2"
            contain
            src="software_dt_fix-06.png"
            transition="scroll-y-transition"
            width="400"
          />
        </v-card-title>
        <v-card-text class="px-0 ma-0">
          <v-alert type="error" v-if="isError">
            ユーザー名とパスワードを正しく入力してください。<br />
            大文字と小文字を区別する必要があります。
          </v-alert>

          <v-form @submit.prevent="onLoginClicked">
            <v-text-field
              prepend-icon="mdi-account-circle"
              label="ユーザ名"
              v-model="name"
            />

            <v-text-field
              prepend-icon="mdi-lock"
              label="パスワード"
              v-model="password"
              v-bind:type="showPassword ? 'text' : 'password'"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
            />

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="info">ログイン</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import token from '../../utils/token';
import Footer from '../layout/Footer.vue';

export default {
  name: 'App',

  components: { Footer },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
  },
  data() {
    return {
      showPassword: false,
      name: '',
      password: '',
      isError: false
    };
  },
  mixins: [token],
  methods: {
    ...mapActions(['login', 'auth']),
    /** ログイン押下時 */
    onLoginClicked() {
      const data = {
        login_id: this.name,
        login_pass: this.password
      };

      this.login(data)
        .then(result => {
          if (result.data.token) {
            this.isError = false;

            localStorage.setItem('token', result.data.token);
            localStorage.setItem('refreshToken', result.data.refreshToken);
            this.auth({
              id: this.getUserId(),
              userId: data.login_id,
              userToken: result.data.token
            });
            this.$router.push('/');
          } else {
            this.isError = true;
          }
        })
        .catch(() => {
          this.isError = true;
        });
    }
  }
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import http from '../../utils/httpClient';

import file from './file';
import fileData from './fileData';
import holiday from './holiday';
import information from './information';
import informationDirectory from './informationDirectory';
import library from './library';
import libraryDirectory from './libraryDirectory';
import position from './position';
import schedules from './schedules';
import schedulesTran from './schedulesTran';
import session from './session';
import user from './user';
import userConfig from './userConfig';
import userGroup from './userGroup';
import workflow from './workflow';
import workflowMap from './workflowMap';
import workflowRoute from './workflowRoute';
import workflowTemplate from './workflowTemplate';
import workflowTemplateRow from './workflowTemplateRow';
import timecard from './timecard';
import facility from './facility';
import facilityGroup from './facilityGroup';
import colorTheme from './colorTheme';
import subscription from './subscription';
import activity from './activity';
import usermenu from './usermenu';
import systemsettings from './systemsettings';

// import createPersistedState from 'vuex-persistedstate'; //https://qiita.com/chenglin/items/0fd9baf386227a5ca614

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    initLoading: true,
    dispNotificationDialog: false
  },
  // TODO:極力sessionStorageに保持するように移行
  // plugins: [createPersistedState({ key: 'Salt' })],
  // plugins: [createPersistedState({ storage: window.sessionStorage })],
  modules: {
    file,
    fileData,
    holiday,
    information,
    informationDirectory,
    library,
    libraryDirectory,
    position,
    schedules,
    schedulesTran,
    session,
    user,
    userConfig,
    userGroup,
    workflow,
    workflowMap,
    workflowRoute,
    workflowTemplate,
    workflowTemplateRow,
    timecard,
    facility,
    facilityGroup,
    colorTheme,
    subscription,
    activity,
    usermenu,
    systemsettings
  },
  mutations: {
    updateInitLoading(state, value) {
      state.initLoading = value;
    },
    updateDispNotificationDialog(state, value) {
      state.dispNotificationDialog = value;
    },

    setCategoryList(state, categoryList) {
      state.categoryList = categoryList;
    },
    setCardList(state, cardList) {
      state.cardList = cardList;
    }
  },
  actions: {
    setInitLoading(context, data) {
      context.commit('updateInitLoading', data);
    },
    setDispNotificationDialog(context, data) {
      context.commit('updateDispNotificationDialog', data);
    },
    async fetchCategoryList(context) {
      await http
        .get('categories')
        .then(categoryList =>
          context.commit('setCategoryList', categoryList.data)
        );
    },
    async fetchCardList(context) {
      await http
        .get('todos')
        .then(cardList => context.commit('setCardList', cardList.data));
    },
    async addCard(context, data) {
      await http.post('todos', data);
    },

    async login(context, data) {
      return await http.post('login', data);
    },

    async refresh(context, data) {
      return await http.post('refresh', data);
    }

    /////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////
  }
});

export default store;

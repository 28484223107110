<template>
  <div @resize="onResize">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          v-model="visibleBadge"
          :content="newCount"
          :value="newCount"
          offset-x="20"
          offset-y="20"
        >
          <v-btn v-bind="attrs" icon v-on="on">
            <v-icon>mdi-bell-ring</v-icon>
          </v-btn>
        </v-badge>
      </template>
      <v-card class="pa-0 ma-0">
        <v-card-text class="pa-0 ma-0">
          <template v-if="dispList.length == 0">
            <v-row class="pa-1 ma-0">
              現在受信している通知はありません。
            </v-row>
          </template>
          <template v-else>
            <v-row class="pa-1 ma-0">
              通知<v-spacer />
              <v-btn color="primary" small @click.stop="onClickAllReaded"
                >全て既読にする</v-btn
              >
            </v-row>

            <v-card
              outlined
              v-for="(item, index) in dispList"
              :key="index"
              @click="onCardClick(item)"
              dense
              v-show="Math.ceil((index + 1) / pageDataCount) == pageIndex + 1"
            >
              <v-toolbar class="pa-0" elevation="0" dense>
                <v-icon v-if="item.is_read === 0" color="primary"
                  >mdi-new-box</v-icon
                >
                <v-toolbar-title class="text-subtitle-2">
                  {{ item.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-title class="text-subtitle-2">
                  {{ getDateFormat(item.inserttime) }}
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="py-0">
                {{ item.message || 'no message' }}</v-card-text
              >
            </v-card>
            <v-row class="pa-1 ma-0" justify="center" align-content="center">
              <v-btn
                color="primary"
                small
                icon
                @click.stop="onClickPrev"
                :disabled="pageIndex <= 0"
                ><v-icon>mdi-chevron-left</v-icon></v-btn
              >
              {{ pageIndex + 1 }} /
              {{ Math.ceil(dispList.length / pageDataCount) }}
              <v-btn
                color="primary"
                small
                icon
                @click.stop="onClickNext"
                :disabled="
                  pageIndex + 1 >= Math.ceil(dispList.length / pageDataCount)
                "
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';
import dateFormat from '../functions/DateFormat';

export default {
  components: {},
  data: () => ({
    name: 'activity',
    config: {},
    dense: true,
    ismobile: false,
    isDataLoding: false,
    dispList: [],
    newCount: 0,
    pageDataCount: 5,
    pageIndex: 0,
    visibleBadge: false,
    windowSize: {
      x: 0,
      y: 0
    },
    menuWidth: 0
  }),
  mounted: function () {
    setInterval(() => {
      if (document.visibilityState !== 'visible') {
        return;
      }
      this.dispActivityList();
    }, 1000 * 60);
  },
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      activity: state => state.activity.activity,
      userConfig: state => state.userConfig.userconfig
    })
  },
  mixins: [fileOperation, token, inputStyleUtils],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchActivity',
      'setActivityReaded'
    ]),
    async init() {
      let config = this.userConfig.find(
        item => item.user_id === this.getUserId()
      );
      this.config.dispStyle = config.dispStyle || 1;

      this.onResize();

      this.dispActivityList();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };

      this.ismobile = window.innerWidth < 600;
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    async dispActivityList() {
      await this.fetchActivity();
      this.dispList = this.$store.state.activity.activity;
      this.newCount = this.dispList.filter(x => x.is_read === 0).length;
      this.visibleBadge = this.newCount > 0;
    },
    getDateFormat(date) {
      return dateFormat.autoFormat(date);
    },
    async onCardClick(activity) {
      try {
        //通知を既読扱いにする
        await this.setActivityReaded(activity);

        //再表示
        await this.dispActivityList();

        const path = activity.app_id;
        const params = JSON.parse(activity.params);
        this.$router.push;
        this.$router.push({
          path: path,
          query: params
        });
      } catch (err) {
        console.log(err);
      }
    },
    async onClickAllReaded() {
      //通知を既読扱いにする
      await Promise.all(
        this.dispList
          .filter(x => x.is_read === 0)
          .map(x => this.setActivityReaded(x))
      );
      //再表示
      await this.dispActivityList();
    },
    async onClickPrev() {
      this.pageIndex -= 1;
    },
    async onClickNext() {
      this.pageIndex += 1;
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}
</style>

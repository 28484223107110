<template>
  <v-card class="mr-5">
    <v-card-title>
      ワークフロー申請経路管理

      <v-btn color="info" class="pa-2 ml-6" @click="openCreateDialog()"
        >追加</v-btn
      >

      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="申請経路検索"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <!-- ユーザーリスト表示テーブル -->
    <v-data-table
      :headers="headers"
      :items="routes"
      item-key="id"
      :search="search"
      class="ma-4"
      :mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr @click="onClickRow(item)">
          <td>{{ item.route_name }}</td>
          <td>{{ getUserName(item.updateuser) }}</td>
          <td>{{ fullDateFormat(item.updatetime) }}</td>
        </tr>
      </template>
    </v-data-table>

    <dialog-frame
      v-model="listDialogFlag"
      @close="listDialogFlag = false"
      @form-clear="resetFormData"
    >
      <template v-slot:title>ワークフロー申請経路順</template>
      <template v-slot:dialogContent>
        <v-sheet>
          <div class="ml-2 mt-4 text-h6">{{ selectedRoute.route_name }}</div>
          <v-container class="px-6 text-body-2">
            <template v-for="(item, key) in userNames">
              <v-row :key="key + 'A'">
                <v-col cols="3">{{ `申請順 ${key + 1}：` }}</v-col>
                <v-col cols="9">{{ item }}</v-col>
              </v-row>
            </template>
          </v-container>
        </v-sheet>
      </template>
      <template v-slot:footer-buttons>
        <v-btn color="info" @click="openUpdateDialog">編集</v-btn>
        <v-btn color="warning" @click="deleteSelectedRoute">削除</v-btn>
        <v-btn color="accent" @click="listDialogFlag = false">キャンセル</v-btn>
      </template>
    </dialog-frame>

    <dialog-frame v-model="editDialogFlag">
      <template v-slot:title>{{
        isNewRoute ? '申請経路追加' : '申請経路更新'
      }}</template>
      <template v-slot:dialogContent>
        <v-container>
          <v-form ref="route_form">
            <v-text-field
              v-model="routeFormData.route_name"
              :rules="[rules.required]"
            >
              <template v-slot:label>
                申請経路名<span style="color: red"> *</span>
              </template>
            </v-text-field>

            <div class="px-3 py-0">
              <multi-user-select-list
                :data="routeFormData.route"
                :label="'所属メンバー'"
                :dispNumber="true"
              />
            </div>
          </v-form>
        </v-container>
      </template>
      <template v-slot:footer-buttons>
        <v-btn
          color="info"
          @click="isNewRoute ? registerRoute() : updateRouteDetail()"
          >{{ isNewRoute ? '追加' : '更新' }}</v-btn
        >
        <v-btn color="accent" @click="closeEditDialog()">キャンセル</v-btn>
      </template>
    </dialog-frame>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import MultiUserSelectList from '../../components/MultiUserSelectList.vue';
import DialogFrame from './dialog_utils/DialogFrame.vue';
import dateformat from '../../functions/DateFormat';

export default {
  components: { MultiUserSelectList, DialogFrame },
  data: () => ({
    path: '/',
    search: '',
    // テーブルのヘッダー
    headers: [
      {
        text: '申請経路名',
        value: 'route_name'
      },
      { text: '更新者', value: 'updateuser' },
      { text: '更新者', value: 'updatetime' }
    ],
    singleSelect: false,
    rules: {
      required: value => !!value || '入力してください'
    },
    selectedRoute: {
      id: 0,
      route_name: '',
      route: [],
      insertuser: 0,
      inserttime: new Date(),
      updateuser: 0,
      updatetime: new Date()
    },
    routeFormData: {
      id: 0,
      route_name: '',
      route: [],
      insertuser: 0,
      inserttime: new Date(),
      updateuser: 0,
      updatetime: new Date()
    },
    routes: [],
    isNewRoute: false,
    listDialogFlag: false,
    editDialogFlag: false,
    deleteDialogFlag: false,
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    isError: {},
    disp_list: [],
    editedFullScreen: false
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      userConfig: state => state.userConfig.userconfig,
      workflowRoute: state => state.workflowRoute.workflowRoute
    }),
    userNames: function () {
      if (!this.selectedRoute.id) {
        return [];
      }
      const selectedRoute = this.selectedRoute;
      return this.usersList
        .filter(x => selectedRoute.route.indexOf(x.id) !== -1)
        .map(x => `${x.last_name} ${x.fast_name}`);
    }
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchUserGroup',
      'fetchWorkflowRoute',
      'insertWorkflowRoute',
      'updateWorkflowRoute',
      'deleteWorkflowRoute',
      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig'
    ]),
    init() {
      this.dispData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;

      this.headers = this.getHeaders();
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },

    dispData() {
      const p = [];
      p.push(this.fetchUserList());
      p.push(this.fetchUserGroup());
      p.push(this.fetchWorkflowRoute());

      Promise.all(p).then(() => {
        this.routes = this.workflowRoute;
      });
    },
    getRouteDetailData() {
      if (!this.selectedRoute.id) {
        return;
      }
      const selectedRoute = this.selectedRoute;
      const route = this.usersList.filter(
        x => selectedRoute.route.indexOf(x.id) !== -1
      );

      return route;
    },
    startEdit() {
      console.log('🐈');
    },
    openUpdateDialog() {
      this.routeFormData = Object.assign({}, this.selectedRoute);
      this.routeFormData.route = Object.assign([], this.selectedRoute.route);
      this.isNewRoute = false;
      this.listDialogFlag = false;
      this.editDialogFlag = true;
    },
    openCreateDialog() {
      this.isNewRoute = true;
      this.editDialogFlag = true;
    },
    closeEditDialog() {
      this.resetFormData();
      this.editDialogFlag = false;
    },
    updateRouteDetail() {
      if (!this.$refs.route_form.validate()) {
        return;
      }
      // 更新者情報の追加
      this.routeFormData.updateuser = this.getUserId();
      this.routeFormData.updatetime = new Date();
      this.updateWorkflowRoute(this.routeFormData).then(res => {
        // 表示されているグループ情報を更新する
        this.routes = this.routes.map(g => {
          if (g.id === res.data.id) {
            g = res.data;
          }
          return g;
        });
        this.editDialogFlag = false;
      });
    },
    registerRoute() {
      if (!this.$refs.route_form.validate()) {
        return;
      }
      // 作成者情報の追加
      this.routeFormData.insertuser = this.getUserId();
      this.routeFormData.inserttime = new Date();
      this.insertWorkflowRoute(this.routeFormData).then(res => {
        this.routes.push(res.data);
        this.editDialogFlag = false;
      });
    },
    deleteSelectedRoute() {
      if (!window.confirm(`この申請経路を削除してよろしいですか？`)) return;
      this.deleteWorkflowRoute(this.selectedRoute).then(() => {
        this.routes = this.routes.filter(g => g.id !== this.selectedRoute.id);
        this.listDialogFlag = false;
      });
    },
    onClickRow(item) {
      this.selectedRoute = item;
      this.listDialogFlag = true;
    },
    // 入力内容と検証エラーをリセットするメソッド
    resetFormData() {
      this.$refs.route_form.reset();
    },

    fullDateFormat(date) {
      return dateformat.fullFormat(date);
    }
  }
};
</script>

import http from '../../utils/httpClient';

const state = {
  informationDirectory: []
};

const mutations = {
  setinformationDirectory(state, informationDirectory) {
    state.informationDirectory = informationDirectory;
  }
};

const actions = {
  /** informationDirectory全取得 */
  async fetchInformationDirectory(context) {
    await http.get('information_directory').then(res => {
      context.commit('setinformationDirectory', res.data);
    });
  },
  /** informationDirectory登録 */
  async insertInformationDirectory(context, data) {
    return await http.post('information_directory', data);
  },
  /** informationDirectory更新 */
  async updateInformationDirectory(context, data) {
    await http.put(`information_directory/${data.id}`, data);
  },
  /** informationDirectory削除 */
  async deleteInformationDirectory(context, data) {
    await http.delete(`information_directory/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};

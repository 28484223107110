<template>
  <custom-dialog v-model="isVisible" :persistent="true">
    <template v-slot:title>パスワード変更</template>
    <template v-slot:body>
      <v-alert type="error" v-if="isError.password"
        >『 現在のパスワード 』を入力してください。
      </v-alert>
      <v-alert type="error" v-if="isError.newpassword"
        >『 新しいパスワード 』を入力してください。
      </v-alert>
      <v-alert type="error" v-if="isError.newpassword2"
        >『 新しいパスワード（確認用） 』を入力してください。
      </v-alert>
      <v-alert type="error" v-if="isError.passwordcheck"
        >『 新しいパスワード 』と『 新しいパスワード（確認用）
        』が一致しません。
      </v-alert>
      <v-alert type="error" v-if="isError.api">{{ isError.apiText }} </v-alert>
      <v-text-field
        class="py-0 pt-5 px-3"
        prepend-icon="mdi-account-circle"
        label="ユーザー名"
        v-model="login_id"
        readonly
      />
      <v-text-field
        class="py-0 px-3"
        prepend-icon="mdi-lock"
        label="現在のパスワード"
        v-model="login_pass"
        v-bind:type="showPassword1 ? 'text' : 'password'"
        v-bind:append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword1 = !showPassword1"
      />
      <v-text-field
        class="py-0 px-3"
        prepend-icon="mdi-dice-1"
        label="新しいパスワード"
        v-model="new_login_pass"
        v-bind:type="showPassword2 ? 'text' : 'password'"
        v-bind:append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword2 = !showPassword2"
      />
      <v-text-field
        class="py-0 px-3"
        prepend-icon="mdi-dice-2"
        label="新しいパスワード(確認用)"
        v-model="new_login_pass2"
        v-bind:type="showPassword3 ? 'text' : 'password'"
        v-bind:append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword3 = !showPassword3"
      />
    </template>
    <template v-slot:footer>
      <v-btn color="info" @click="endEdit" v-html="'更新'" />
      <v-btn color="accent" @click="isVisible = false" v-html="'キャンセル'" />
    </template>
  </custom-dialog>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import CustomDialog from '../../components/CustomDialog.vue';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';

export default {
  components: { CustomDialog },
  props: {
    value: { type: Boolean, default: false }
  },
  data: () => ({
    name: 'userpasswordeditdialog',
    path: '/userpasswordeditdialog',
    fullScreen: false,
    showPassword1: false,
    showPassword2: false,
    showPassword3: false,
    login_id: '',
    login_pass: '',
    new_login_pass: '',
    new_login_pass2: '',
    newPassword: '',
    isError: {
      password: false,
      newpassword: false,
      newpassword2: false,
      passwordcheck: false,
      api: false,
      apiText: ''
    }
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    isVisible(val, old) {
      console.log('watch', val, old);
      if (val) {
        this.init();
      }
    },
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      userConfig: state => state.userConfig.userconfig
    }),
    isVisible: {
      get() {
        return this.value;
      },
      set(val) {
        //v-modelに値を返す（なのでthis.valueも書き換わる）
        this.$emit('input', val);
      }
    }
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions(['fetchUserList', 'changeUserPassword']),
    init() {
      this.login_id = '';
      this.login_pass = '';
      this.new_login_pass = '';
      this.new_login_pass2 = '';

      this.dispData();
    },
    onResize() {
      this.fullScreen = window.innerWidth < 600;
    },
    dispData() {
      const p = [];
      this.errrorReset();

      p.push(this.fetchUserList());

      Promise.all(p).then(() => {
        const user = this.usersList.find(x => x.id === this.getUserId());
        this.login_id = user.login_id;
      });
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      this.errrorReset();
      //現在のパスワード
      this.isError.password = this.login_pass === '';
      //新しいパスワード
      this.isError.newpassword = this.new_login_pass === '';
      //新しいパスワード(確認用)
      this.isError.newpassword2 = this.new_login_pass2 === '';
      //パスワード２
      this.isError.passwordcheck = this.new_login_pass !== this.new_login_pass2;

      for (var key in this.isError) {
        if (this.isError[key]) {
          return false;
        }
      }
      return true;
    },

    endEdit() {
      //入力チェック
      if (!this.inputCheck()) return;

      const data = {
        id: this.getUserId(),
        login_id: this.login_id,
        login_pass: this.login_pass,
        new_login_pass: this.new_login_pass
      };
      this.changeUserPassword(data)
        .then(result => {
          this.registPassword(result);
        })
        .catch(err => {
          console.log(err);
          this.isError.api = true;
          if (err.response.status === 400) {
            const data = JSON.parse(err.response.data);
            //パスワード不一致
            this.isError.apiText = data.message;
          } else {
            this.isError.apiText = err;
          }
        });
    },
    registPassword(result) {
      this.isVisible = false;
      this.newPassword = result.data.newPassword;
    }
  }
};
</script>

<template>
  <v-card class="mr-5">
    <v-card-title>
      ユーザー管理
      <v-btn color="info" class="pa-2 ml-6" @click="openCreateDialog()"
        >追加</v-btn
      >

      <v-spacer />
      <!-- ユーザー検索フィールド -->
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="ユーザー検索"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <!-- ユーザーリスト表示テーブル -->
    <v-data-table
      :headers="getHeaders()"
      :items="users"
      item-key="id"
      :search="search"
      class="ma-4"
      :item-class="rowClass"
      sort-by="id"
      :mobile-breakpoint="0"
    >
      <template v-slot:item="{ item }">
        <tr :class="rowClass(item)" @click="onClickRow(item)">
          <td v-if="!$vuetify.breakpoint.xs">{{ item.id }}</td>
          <td>{{ item.login_id }}</td>
          <td>
            {{ item.disp_name }}
          </td>
          <td>{{ item.e_mail }}</td>
          <td v-if="!$vuetify.breakpoint.xs">
            {{ getPositionName(item.position) }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <dialog-frame v-model="listDialogFlag" @close="listDialogFlag = false">
      <template v-slot:title>ユーザー詳細</template>
      <template v-slot:dialogContent>
        <v-sheet>
          <v-container class="px-6 text-body-2">
            <template v-for="(item, key) in getUserDetailData()">
              <v-row :key="key + 'A'">
                <v-col cols="3" md="6">{{ item.title }}</v-col>
                <v-col cols="9" md="6">{{
                  item.text ? item.text : '未入力'
                }}</v-col>
              </v-row>
            </template>
          </v-container>
        </v-sheet>
      </template>
      <template v-slot:footer-buttons>
        <v-btn color="info" @click="openUpdateDialog">編集</v-btn>
        <v-btn color="warning" @click="deleteSelectedUser">削除</v-btn>
        <v-btn color="accent" @click="listDialogFlag = false">キャンセル</v-btn>
      </template>
    </dialog-frame>

    <dialog-frame v-model="editDialogFlag" @form-clear="resetFormData">
      <template v-slot:title>{{
        isNewUser ? 'ユーザー追加' : 'ユーザー更新'
      }}</template>
      <template v-slot:dialogContent>
        <v-container class="mt-4">
          <ValidationObserver ref="observer">
            <v-form ref="user_form">
              <validation-provider
                v-slot="{ errors }"
                name="ユーザー名"
                rules="required|hankaku_eisu"
              >
                <v-text-field
                  v-model="userFormData.login_id"
                  :error-messages="errors"
                  ref="rf1"
                >
                  <template v-slot:label>
                    ユーザー名(半角英数)<span style="color: red"> *</span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider
                name="パスワード"
                v-slot="{ errors }"
                rules="hankaku_eisu"
                vid="confirmation"
              >
                <v-text-field
                  :type="showPassword1 ? 'text' : 'password'"
                  v-if="isNewUser"
                  v-model="userFormData.login_pass"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :error-messages="errors"
                  ref="rf2"
                >
                  <template v-slot:label>
                    パスワード<span style="color: red"> *</span>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider
                name="パスワード"
                rules="confirmed:confirmation|hankaku_eisu"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="passConfirmation"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  v-if="isNewUser"
                  :error-messages="errors"
                  ref="rf3"
                >
                  <template v-slot:label>
                    パスワード(確認用)<span style="color: red"> *</span>
                  </template>
                </v-text-field>
              </validation-provider>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      name="姓"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="userFormData.last_name"
                        :error-messages="errors"
                        dense
                        ref="rf4"
                      >
                        <template v-slot:label>
                          姓<span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="名"
                      rules="required"
                    >
                      <v-text-field
                        v-model="userFormData.fast_name"
                        :error-messages="errors"
                        dense
                        ref="rf5"
                      >
                        <template v-slot:label>
                          名<span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="姓(フリガナ)"
                      rules="required"
                    >
                      <v-text-field
                        v-model="userFormData.last_kana"
                        :error-messages="errors"
                        dense
                        ref="rf6"
                      >
                        <template v-slot:label>
                          姓(フリガナ)<span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="名(フリガナ)"
                      rules="required"
                    >
                      <v-text-field
                        v-model="userFormData.fast_kana"
                        :error-messages="errors"
                        dense
                        ref="rf7"
                      >
                        <template v-slot:label>
                          名(フリガナ)<span style="color: red"> *</span>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-container>
              <validation-provider
                name="メールアドレス"
                rules="hankaku_eisu|email"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="userFormData.e_mail"
                  :error-messages="errors"
                  ref="rf8"
                >
                  <template v-slot:label> メールアドレス </template>
                </v-text-field>
              </validation-provider>
              <template>
                <label for="outside-line">電話番号(外線)</label>
                <div class="d-flex justify-center" id="outside-line">
                  <validation-provider
                    name="電話番号1(外線)"
                    rules="numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="outsideLineElms[0]"
                      dense
                      ref="rf9"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                  <span class="mx-2 pt-3">-</span>
                  <validation-provider
                    name="電話番号2(外線)"
                    rules="numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="outsideLineElms[1]"
                      dense
                      ref="rf10"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                  <span class="mx-2 pt-3">-</span>
                  <validation-provider
                    name="電話番号3(外線)"
                    rules="numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="outsideLineElms[2]"
                      dense
                      ref="rf11"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </div>
              </template>
              <template>
                <label for="outside-line">電話番号(内線)</label>
                <div class="d-flex justify-center" id="outside-line">
                  <v-row>
                    <v-col cols="6">
                      <validation-provider
                        name="電話番号(外線)"
                        rules="numeric_and_hyphen"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="userFormData.extension"
                          dense
                          ref="rf12"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </div>
              </template>

              <template>
                <label for="outside-line">電話番号(携帯)</label>
                <div class="d-flex justify-center">
                  <validation-provider
                    name="電話番号1(携帯)"
                    rules="numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="cellphoneNumberElms[0]"
                      dense
                      ref="rf13"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>

                  <span class="mx-2 pt-3">-</span>
                  <validation-provider
                    name="電話番号2(携帯)"
                    rules="numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="cellphoneNumberElms[1]"
                      dense
                      ref="rf14"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>

                  <span class="mx-2 pt-3">-</span>
                  <validation-provider
                    name="電話番号3(携帯)"
                    rules="numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="cellphoneNumberElms[2]"
                      dense
                      ref="rf15"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </div>
              </template>
              <validation-provider
                name="携帯メールアドレス"
                rules="hankaku_eisu|email"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="userFormData.cellphone_email"
                  :error-messages="errors"
                  ref="rf16"
                >
                  <template v-slot:label> 携帯メールアドレス </template>
                </v-text-field>
              </validation-provider>

              <v-select
                v-model="positionId"
                class="px-3"
                prepend-icon="mdi-bookmark"
                label="役職"
                item-text="disp_name"
                item-value="id"
                :items="getPosition()"
              />
              <validation-provider
                name="社員コード"
                rules="hankaku_eisu"
                v-slot="{ errors }"
              >
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="userFormData.staff_code"
                      label="社員コード"
                      :error-messages="errors"
                      ref="rf17"
                    />
                  </v-col>
                </v-row>
              </validation-provider>
              <v-row>
                <label></label>
                <v-col cols="6">
                  <v-checkbox
                    v-model="userFormData.admin"
                    :label="`管理者権限：${
                      userFormData.admin ? 'あり' : 'なし'
                    }`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="userFormData.enable"
                    :label="`ユーザー状態：${
                      userFormData.enable ? '有効' : '無効'
                    }`"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-container>
      </template>
      <template v-slot:footer-buttons>
        <v-btn
          color="info"
          @click="isNewUser ? createUserDetail() : updateUserDetail()"
          >{{ isNewUser ? '登録' : '更新' }}</v-btn
        >
        <v-btn color="accent" @click="closeEditDialog()">キャンセル</v-btn>
      </template>
    </dialog-frame>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import DialogFrame from './dialog_utils/DialogFrame.vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  setInteractionMode
} from 'vee-validate';
import { required, email, confirmed, numeric } from 'vee-validate/dist/rules';

// バリデーションチェックをfocusが外れたときに行うようグローバル設定
setInteractionMode('lazy');

export default {
  components: {
    DialogFrame,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    path: '/',
    search: '',
    outsideLineElms: ['', '', ''],
    cellphoneNumberElms: ['', '', ''],
    positionId: -1,
    isNewUser: true,
    selectedUser: {},
    passConfirmation: '',
    users: [
      {
        id: 1,
        state: '有効',
        login_id: 'admin1',
        disp_name: '管理者1',
        department: 'SectionS',
        position: -1
      }
    ],
    userFormData: {
      id: 0,
      login_id: '',
      login_pass: '',
      last_name: '',
      fast_name: '',
      last_kana: '',
      fast_kana: '',
      telNumber: '',
      e_mail: '',
      position: '',
      staff_code: '',
      admin: false,
      enable: true,
      insertuser: 0,
      inserttime: new Date(),
      updateuser: 0,
      updatetime: new Date()
    },
    editedFullScreen: false,
    checkbox: true,
    showPassword1: false,
    showPassword2: false,
    listDialogFlag: false,
    editDialogFlag: false,
    deleteDialogFlag: false,
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    isError: {},
    disp_list: []
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      userConfig: state => state.userConfig.userconfig,
      position: state => state.position.positionData
    }),
    outsideLine: {
      get() {
        return `${this.outsideLineElms[0]}-${this.outsideLineElms[1]}-${this.outsideLineElms[2]}`;
      }
    },
    cellphoneNumber: {
      get() {
        return `${this.cellphoneNumberElms[0]}-${this.cellphoneNumberElms[1]}-${this.cellphoneNumberElms[2]}`;
      }
    }
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'updateUserList',
      'insertUser',
      'deleteUser',
      'fetchUserGroup',
      'updateUserGroup',
      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig',
      'fetchPositionData'
    ]),
    confirmPass(value) {
      return value !== this.userFormData.login_pass;
    },
    init() {
      this.dispData();
    },
    initUserFormData() {
      this.userFormData;
    },
    getHeaders() {
      const id = {
        text: 'ID',
        value: 'id'
      };
      const loginId = {
        text: 'ユーザー名',
        value: 'loginId'
      };

      const disp_name = {
        text: '名前',
        value: 'disp_name'
      };
      const e_mail = {
        text: 'メールアドレス',
        value: 'e_mail'
      };
      const position = {
        text: '役職',
        value: 'position'
      };
      if (this.$vuetify.breakpoint.xs) {
        return [loginId, disp_name, e_mail];
      } else {
        return [id, loginId, disp_name, e_mail, position];
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.path;
    },
    getPosition() {
      const ret = [];
      ret.push({ id: -1, disp_name: '未指定' });
      ret.push(...this.position);
      return ret;
    },
    getPositionName(positionId) {
      let disp_name = '';
      if (positionId !== -1 && positionId != null) {
        disp_name = this.position[positionId - 1].disp_name;
      }
      return disp_name;
    },
    getUserDetailData() {
      if (!this.selectedUser.id) {
        return;
      }
      const dispData = [];
      const detailUserData = this.users.find(
        u => u.id === this.selectedUser.id
      );
      if (!detailUserData) {
        return;
      }
      dispData.push({ title: 'ユーザー名', text: detailUserData.login_id });
      dispData.push({
        title: '名前',
        text: detailUserData.disp_name
      });
      dispData.push({
        title: 'フリガナ',
        text: detailUserData.last_kana + ' ' + detailUserData.fast_kana
      });
      dispData.push({
        title: 'メールアドレス',
        text: detailUserData.e_mail
      });
      dispData.push({
        title: '電話番号（外線）',
        text: detailUserData.outside_line
      });
      dispData.push({
        title: '電話番号（内線）',
        text: detailUserData.extension
      });
      dispData.push({
        title: '電話番号（携帯）',
        text: detailUserData.cellphone_number
      });
      dispData.push({
        title: '携帯メールアドレス',
        text: detailUserData.cellphone_email
      });

      let dispName;
      if (detailUserData.position) {
        dispName = this.position.filter(p => p.id == detailUserData.position)[0]
          .disp_name;
      }
      dispData.push({ title: '役職', text: dispName });
      dispData.push({ title: '社員コード', text: detailUserData.staff_code });
      dispData.push({
        title: '管理者権限',
        text: detailUserData.admin ? 'あり' : 'なし'
      });
      dispData.push({
        title: 'ユーザー状態',
        text: detailUserData.enable ? '有効' : '無効'
      });

      return dispData;
    },
    dispData() {
      const p = [];
      p.push(this.fetchUserList());
      p.push(this.fetchUserGroup());
      p.push(this.fetchPositionData());

      Promise.all(p).then(() => {
        this.users = this.usersList;
      });
    },
    startEdit() {
      console.log('🐈');
    },
    openCreateDialog() {
      this.isNewUser = true;
      this.editDialogFlag = true;
      this.positionId = -1; // プルダウンを[未指定]選択状態にする。
      this.userFormData.enable = true;
    },
    openUpdateDialog() {
      this.listDialogFlag = false;
      this.isNewUser = false;
      const item = this.selectedUser;
      const olElms = item.outside_line
        ? item.outside_line.split('-')
        : ['', '', ''];
      for (let i = 0; i < 3; i++) {
        // v-modelに配列の要素を登録しているので、リアクティブに更新するためにthis.$setを使う
        this.$set(this.outsideLineElms, i, olElms[i]);
      }
      const cnElms = item.cellphone_number
        ? item.cellphone_number.split('-')
        : ['', '', ''];
      for (let i = 0; i < 3; i++) {
        // v-modelに配列の要素を登録しているので、リアクティブに更新するためにthis.$setを使う
        this.$set(this.cellphoneNumberElms, i, cnElms[i]);
      }
      this.userFormData = {
        id: item.id,
        login_id: item.login_id,
        login_pass: item.login_pass,
        last_name: item.last_name,
        fast_name: item.fast_name,
        disp_name: item.disp_name,
        last_kana: item.last_kana,
        fast_kana: item.fast_kana,
        extension: item.extension,
        e_mail: item.e_mail,
        position: item.position,
        staff_code: item.staff_code,
        admin: item.admin,
        enable: item.enable,
        insertuser: item.insertuser,
        inserttime: item.inserttime,
        updateuser: item.updateuser,
        updatetime: item.updatetime,
        cellphone_email: item.cellphone_email
      };
      this.positionId = item.position ? item.position : -1;
      this.isNewUser = false;
      this.editDialogFlag = true;
    },
    closeEditDialog() {
      this.resetFormData();
      this.editDialogFlag = false;
    },
    async createUserDetail() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      this.userFormData.position = undefined;
      // 役職が指定された場合
      if (this.positionId !== -1) {
        // const position = this.position.filter(p => p.id === this.positionId)[0];
        this.userFormData.position = this.positionId;
      }

      // フリガナの入力がひらがなの場合はカタカナに変換
      this.userFormData.last_kana = this.hiraToKata(
        this.userFormData.last_kana
      );
      this.userFormData.fast_kana = this.hiraToKata(
        this.userFormData.fast_kana
      );

      // 表示名はフルネーム
      this.userFormData.disp_name =
        this.userFormData.last_name + ' ' + this.userFormData.fast_name;

      // 3つのフォームデータからcomputedを用いて電話番号を組み立てる。
      this.userFormData.outside_line = this.outsideLine;
      this.userFormData.cellphone_number = this.cellphoneNumber;

      // 作成者情報の追加
      this.userFormData.insertuser = this.getUserId();
      this.userFormData.inserttime = new Date();

      this.insertUser(this.userFormData).then(res => {
        this.users.push(res.data);
        this.resetFormData();
        this.editDialogFlag = false;
      });
    },
    async updateUserDetail() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      this.userFormData.position = null;
      // 役職が指定された場合;
      if (this.positionId !== -1) {
        const position = this.position.filter(p => p.id === this.positionId)[0];
        this.userFormData.position = position.id;
      }

      // フリガナの入力がひらがなの場合はカタカナに変換
      this.userFormData.last_kana = this.hiraToKata(
        this.userFormData.last_kana
      );
      this.userFormData.fast_kana = this.hiraToKata(
        this.userFormData.fast_kana
      );

      // 表示名はフルネーム
      this.userFormData.disp_name =
        this.userFormData.last_name + ' ' + this.userFormData.fast_name;

      // 3つのフォームデータからcomputedを用いて電話番号を組み立てる。
      this.userFormData.outside_line = this.outsideLine;
      this.userFormData.cellphoneNumber = this.cellphoneNumber;

      // 更新者情報の追加
      this.userFormData.updateuser = this.getUserId();
      this.userFormData.updatetime = new Date();
      this.updateUserList(this.userFormData).then(res => {
        // 表示されているグループ情報を更新する
        this.users = this.users.map(u => {
          if (u.id === res.data.id) {
            u = res.data;
          }
          return u;
        });

        this.resetFormData();
        this.editDialogFlag = false;
      });
    },
    deleteSelectedUser() {
      //削除確認
      if (!window.confirm(`このユーザーを削除してよろしいですか？`)) return;
      this.deleteUser(this.selectedUser.id).then(() => {
        this.users = this.users.filter(u => u.id !== this.selectedUser.id);
        this.listDialogFlag = false;
      });
    },
    rowClass(item) {
      return item.enable ? '' : 'rowBackGround';
    },
    onClickRow(item) {
      this.selectedUser = item;
      this.listDialogFlag = true;
    },
    // 入力内容と検証エラーをリセットするメソッド
    resetFormData() {
      // フォームの入力データをリセット
      this.$refs.user_form.reset();
      this.positionId = -1;
      // バリエーションメッセージのリセット
      this.$refs.observer.reset();
    },
    hiraToKata(str) {
      return str.replace(/[\u3041-\u3096]/g, ch =>
        String.fromCharCode(ch.charCodeAt(0) + 0x60)
      );
    }
  }
};

// validationのルール設定
extend('email', {
  ...email,
  message: 'メールアドレスの形式に誤りがあります。'
});

extend('required', {
  ...required,
  message: '{_field_}は必須項目です。'
});

extend('numeric', {
  ...numeric,
  message: '半角数字を入力してください。'
});

extend('confirmed', {
  ...confirmed,
  message: 'パスワードが一致しません。'
});

// * カスタムルール
extend('hankaku_eisu', {
  message: '半角英数で入力してください',
  validate(value) {
    return /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/.test(value);
  }
});

extend('numeric_and_hyphen', {
  message: 'ハイフンまたは半角数字で入力してください',
  validate(value) {
    return /^[0-9-]*$/.test(value);
  }
});
</script>

<style lang="css">
.rowBackGround {
  color: darkgray;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

import http from '../../utils/httpClient';

const state = {
  usergroup: []
};

const mutations = {
  setFacilityGroup(state, data) {
    state.facilityGroup = data;
  }
};

const actions = {
  async fetchFacilityGroup(context) {
    await http.get('facilitygroup').then(res => {
      context.commit('setFacilityGroup', res.data);
    });
  },
  async updateFacilityGroup(context, data) {
    return await http.put(`facilitygroup/${data.id}`, data);
  },
  async insertFacilityGroup(context, data) {
    return await http.post('facilitygroup', data);
  },
  async deleteFacilityGroup(context, data) {
    await http.delete(`facilitygroup/${data.id}`);
  }
};

export default {
  state,
  mutations,
  actions
};
